import { fromJSON } from '../util/json-utils';
import { PropsObject } from './Base'
import Platform from './Platform'

interface IGameProps {
    name: string
    thumbnail: string
    intervals: { [name: string]: string[] }
    platforms: Platform[]
}

export default class Game extends PropsObject<Game, IGameProps> {

    constructor(props: IGameProps, id: string) {
        // FIXME: remove non-null assert
        props.platforms = props.platforms.map(plat => fromJSON(plat, Platform)!)
        super(props, id)
    }
    getName() {
        return this.props.name
    }

    getIntervals() {
        return {
            "chapters": [
                "Chapter 1: The Marked Trees",
                "Chapter 2: Path to the Mountain",
                "Chapter 3: A Realm Beyond",
                "Chapter 4: The Light of Alfheim",
                "Chapter 5: Inside the Mountain",
                "Chapter 6: A New Destination",
                "Chapter 7: The Magic Chisel",
                "Chapter 8: Behind the Lock",
                "Chapter 9: The Sickness",
                "Chapter 10: The Black Rune",
                "Chapter 11: Return to the Summit",
                "Chapter 12: Escape from Helheim",
                "Chapter 13: A Path to Jotunheim",
                "Chapter 14: Between the Realms",
                "Chapter 15: Jotunheim in Reach",
                "Chapter 16: Mother’s Ashes",
                "Chapter 17: The Journey Home"
            ],
            "bosses": [
                "Some boss 1",
                "Some boss 2",
                "You"
            ]
        }
        return this.props.intervals
    }

    getPlatforms() {
        return this.props.platforms
    }

    toString(): string {
        return this.getName()
    }
}