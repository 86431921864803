import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Box, Card, CardHeader, Container, Grid } from '@material-ui/core';
import review_data from '../../test-data/reviews.json'
import { ReviewPreviewCard } from '../../ui/ReviewCard';
import Review from "../../entities/Review"
import { getObjectStore } from '../../util/json-utils';
import { RouteComponentProps } from 'react-router';
import Cookie from 'universal-cookie'
import { useCookies } from 'react-cookie';
import { getTokenSourceMapRange } from 'typescript';
import User from '../../entities/User';
import { getRefreshTokenWithAuthCode } from '../../api/auth';
import { strict } from 'assert';
// import { getTokensWithAuthCode } from '../../api/auth';

interface IPostLoginProps {
    routerProps: RouteComponentProps
}

export default function PostLoginPage(props: IPostLoginProps) {
    const { routerProps } = props
    const hash = new URLSearchParams(routerProps.location.hash.substr(1))
    const code = new URLSearchParams(routerProps.location.search).get('code')
    const id_token = hash.get('id_token')?.split('.')
    const [id_data, setIdData] = useState<any>()
    const [cookies, setCookie, removeCookie] = useCookies();

    if (id_token) {
        setIdData(JSON.parse(atob(id_token[1])))
    }

    useEffect(() => {
        async function getToken() {
            if (code) {
                let responseJSON = await getRefreshTokenWithAuthCode(code)
                console.log('responseJSON :>> ', responseJSON);
                console.log('responseJSON["access_token"] :>> ', responseJSON["access_token"]);
                console.log('responseJSON["refresh_token"] :>> ', responseJSON["refresh_token"]);
                // localStorage.setItem('refresh_token', responseJSON["refresh_token"])
                // console.log('localStorage refresh_token :>> ', localStorage.getItem('refresh_token'));
                // console.log("Setting the ID")
                // let data = JSON.parse(atob(responseJSON["access_token"].split('.')))
                try {
                    // let data: any[] = responseJSON["refresh_token"].split('.')
                    // data = data.map((val: string) => {
                    //     console.log('val :>> ', val);
                    //     try {
                    //         return JSON.parse(atob(val))
                    //     }
                    //     catch (err) { }
                    // })
                    // console.log(JSON.stringify(data, null, 2))
                    // setIdData({ data: data, encoded: responseJSON["refresh_token"] })
                    // setCookie("refresh_token", responseJSON["refresh_token"], { httpOnly: true, secure: true, path: '/' })
                    // setCookie("test_cookie", "i'm a cookie", { path: '/' })
                    let user = new User(
                        {
                            username: responseJSON["username"]
                        },
                        responseJSON["username"]
                    )
                    localStorage.setItem('user', JSON.stringify(user))
                    window.dispatchEvent(new Event('storage'))
                    // setCookie('username', data[1]['username'] || data[1]['cognito:username'])
                }
                catch (err) {
                    console.log(err)
                }
            }
        }
        console.log("Running useEffect")
        if (code !== null && id_data === undefined) {
            getToken()
        }
        // setCookie('testHTTP', 'some string', { httpOnly: false, domain: 'dev.percentplayed.com', path: '/data', sameSite: 'lax', secure: true })
        // setCookie('test', 'some string', { httpOnly: false, path: '/', sameSite: 'lax' })
    }, [])

    return (
        <Container maxWidth="xl">
            <Typography style={{ color: 'white' }}>Post Login</Typography>
            <pre style={{ color: 'white' }}>{JSON.stringify(routerProps, null, 2)}</pre>
            {id_data && <>

                {id_data.data.map((data: any) => {
                    return <pre style={{ color: "white" }}>{JSON.stringify(data, null, 2)}</pre>
                })}
                <pre style={{ color: "white" }}>{id_data.encoded}</pre>
            </>
            }

        </Container>
    )
}