import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import { green, grey, lightBlue, lightGreen, orange, purple } from '@material-ui/core/colors';
import { CookiesProvider } from 'react-cookie';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: purple[800],
        },
        secondary: {
            main: lightGreen["A700"],
            // main: lightGreen["A400"],
        },
        background: {
            // default: "black",
            default: grey[800],
            // paper: grey[800]
        }
    },
    typography: {
        fontFamily: "'Roboto Mono', monospace"
    }
});

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CssBaseline>
            <React.StrictMode>
                <CookiesProvider>
                    <App />
                </CookiesProvider>
            </React.StrictMode>
        </CssBaseline>
    </ThemeProvider>

    ,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
