import User from "../entities/User"
import { fromJSON } from "../util/json-utils"

const FUNCTIONS_URL = 'https://dev.percentplayed.com'

export async function getLoginURL(): Promise<string> {
    let response = await fetch(`${FUNCTIONS_URL}/auth/login`)
    let responseJSON = await response.json()
    return responseJSON.url
}

export async function getLogoutURL(): Promise<string> {
    let response = await fetch(`${FUNCTIONS_URL}/auth/logout`)
    let responseJSON = await response.json()
    return responseJSON.url
}

export async function getRefreshTokenWithAuthCode(authCode: string) {
    let url = new URL(`${FUNCTIONS_URL}/auth/token`)
    url.searchParams.set('code', authCode)
    console.log(`getting RT from ${url.toString()}`)
    let response = await fetch(url.toString())
    return await response.json()
}

// export function getRefreshToken(): string {
//     let user = getCurrentUser()
//     if (user?.getRefreshToken() == null) throw new Error("No valid refresh token")
//     return user?.getRefreshToken()
// }

export function getCurrentUser() {
    let storedUser = localStorage.getItem('user')
    if (!storedUser) return null
    let user = fromJSON(JSON.parse(storedUser), User)
    return user
}