export abstract class Base<T> {

    abstract clone(): T

    toJSON() {
        return this
    }

    toString() {
        return JSON.stringify(this)
    }
}


export class PropsObject<T, Props> extends Base<T>{

    protected _id
    protected props

    constructor(props: Props, id?: string) {
        super()
        this._id = id
        this.props = props
    }

    get id() {
        return this._id
    }

    clone(): T {
        let that = this as any
        return new that.constructor(JSON.parse(JSON.stringify(this.props)), this._id?.toString())
    }
}

