import React from 'react'
import { Line } from 'react-chartjs-2'
import { ChartData, ChartOptions, ChartDataSets } from 'chart.js';
import Review from '../entities/Review';
import { useTheme } from '@material-ui/core';
import { toTitleCase } from '../util/text-utils';
import ReviewSegment from '../entities/ReviewSegment';

interface IGraphProps {
    review: Review
}

export default function Graph(props: IGraphProps) {

    const { review } = props
    // console.log('review.getGame().getIntervals() :>> ', review.getGame().getIntervals().chapters);
    const reviewSegs = review.getSegments()
    const labels: string[] = reviewSegs.reduce((arr, seg) => {
        if (seg.isType("chapter")) {
            let chapter_name = seg.getChapterName()!
            arr.push(chapter_name)
        }
        return arr
    }, [] as string[])

    const scores = Object.values(reviewSegs).map(seg => seg.getOverallScore())
    let customScores: { [score_name: string]: (number | undefined)[] } = {}
    let customScoreNames = review.getCustomScoreNames()
    if (customScoreNames.length > 0) {
        Object.values(reviewSegs).forEach(seg => {
            let segCustomScores = seg.getCustomScores()
            for (let scoreName of customScoreNames) {
                if (customScores[scoreName] === undefined) customScores[scoreName] = []
                if (scoreName in segCustomScores) {
                    customScores[scoreName].push(segCustomScores[scoreName])
                }
                else {
                    customScores[scoreName].push(undefined)
                }

            }
        })
    }

    const customDatasets = Object.keys(customScores).map(cs => createDataset(cs, customScores[cs], ReviewSegment.getScoreColour(cs)))
    const overallDataset = createDataset("Overall", scores, ReviewSegment.getScoreColour("Overall"))

    const data: ChartData = {
        labels: labels,
        datasets: [
            overallDataset,
            ...customDatasets
        ],

    }
    const options: ChartOptions = {
        scales: {
            yAxes: [{
                type: "linear",
                // position: 'left',
                display: true,
                ticks: {
                    suggestedMin: 0,
                    suggestedMax: 10,
                    autoSkipPadding: 10
                    // display: true,
                    // beginAtZero: true,
                },
                scaleLabel: {
                    labelString: "Score",
                    display: true
                }
            }],
            xAxes: [{
                // type: "linear",
                // display: true,
                ticks: {
                    fontSize: 10,
                    display: false
                },
                scaleLabel: {
                    labelString: "Chapter",
                    display: true
                }

            }]
        },
        maintainAspectRatio: false,
        layout: {
            padding: {
                right: 20,
                bottom: -5,
            }
        },
    }

    function createDataset(scoreName: string, scores: (number | undefined)[], colour: string): ChartDataSets {
        let label = toTitleCase(scoreName)
        console.log('scores :>> ', scores);
        return {
            label: label,
            fill: false,
            lineTension: 0.1,
            backgroundColor: colour,
            borderColor: colour,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: colour,
            pointBackgroundColor: colour,
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: colour,
            pointHoverBorderColor: colour,
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 10,
            spanGaps: true,
            data: scores
        }
    }

    return (
        <Line data={data} options={options} />
    )
}
