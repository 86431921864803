import { SvgIcon } from "@material-ui/core"


export function getPlatformFamily(platform: string) {
    let platformFamily = 'default'
    let platformLower = platform.toLowerCase()
    if (platformLower.includes('playstation')) {
        platformFamily = 'playstation'
    }
    else if (platformLower.includes('xbox')) {
        platformFamily = 'xbox'
    }
    else if (platformLower.includes('switch')) {
        platformFamily = 'switch'
    }
    else if (platformLower.includes('pc')
        || platformLower.includes('linux')
        || platformLower.includes('mac')) {
        platformFamily = 'pc'
    }
    return platformFamily
}

export function getPlatformIcon(platform: string, fontSize: any = 40, greyed?: boolean) {
    let platformFamily = getPlatformFamily(platform)
    let node: any
    let viewBox = "0 0 512 512"
    let colour = greyed ? "#AAAAAA" : ""
    let viewBoxSize = 0
    let scale = 0
    let innerViewBox = "0 0 512 512"
    switch (platformFamily) {
        case "playstation":
            colour = colour === "" ? "#003087" : colour
            node = <>
                <circle cx="256" cy="256" fill={colour} id="ellipse" r="256" />
                <path d="M287.9,184.8c0.9,4.3,0.6,8.7,0.6,13.1c0,24.1-0.1,48.2,0.1,72.4c7.3,3.4,15.1,6,23.2,5.9  c5.3,0.1,10.7-1.1,15.4-3.6c4.9-2.6,8.9-6.7,11.8-11.4c3.3-5.3,5.3-11.4,6.4-17.5c1.3-7.1,1.4-14.3,1.1-21.5  c-0.5-11-2.1-22.1-6.9-32.1c-2.6-5.5-6.2-10.6-10.6-14.9c-4-4.1-8.8-7.5-13.7-10.5c-11-6.5-23-10.9-35.2-14.7  c-7.7-2.5-15.5-4.8-23.3-7.1c-13.6-3.9-27.3-7.6-41.2-10.2c0,76.8,0,153.6,0,230.4c17.4,5.5,34.7,11,52.1,16.5  c0-64.6,0-129.1,0-193.7c0.1-3.7,0.7-7.6,3.1-10.5c1.4-1.8,3.9-2.8,6.1-2.4c2.3,0.5,4.5,1.4,6.2,2.9  C285.9,178.1,287.2,181.4,287.9,184.8z M151.8,321.3c17.9-6.4,35.8-12.8,53.7-19.2c0.2-1.2,0.1-2.4,0.1-3.6c0-8.9,0-17.8,0-26.7  c-22.7,8-45.3,16.1-67.9,24.1c-5.5,2-11.1,3.8-16.5,6.1c-6.3,2.6-12.5,5.6-18.1,9.5c-2.4,1.7-4.6,3.8-5.9,6.5  c-1.3,2.7-1.4,5.9-0.3,8.7c1.3,3.3,3.7,6,6.5,8.1c5,3.8,11,6.1,17,7.9c18.3,6,37.6,9,56.8,8.6c9.6-0.2,19.1-1.3,28.5-3  c0.1-1.5,0.1-3,0.1-4.5c0-7.5,0-15.1,0-22.6c-7.7,2.7-15.3,5.5-22.9,8.3c-2.9,1.1-5.9,2.1-9,2.7c-4.6,1-9.2,1.4-13.9,1.4  c-4.2-0.2-8.5-0.4-12.4-2c-1.4-0.6-2.9-1.4-3.6-2.9c-0.5-1.3,0.2-2.6,1.1-3.5C146.7,323.3,149.3,322.3,151.8,321.3z M410.9,304  c-4.5-4.1-10.2-6.6-15.9-8.6c-3.2-1-6.3-2.3-9.5-3.2c-16.2-5.1-33.3-7.2-50.3-7.3c-5.2,0.2-10.3,0.3-15.5,0.9  c-14.3,1.6-28.5,4.5-42.1,9.3c0,10.4,0,20.9,0,31.3c18.8-6.6,37.6-13.2,56.4-19.8c6.3-2.1,12.9-2.9,19.5-3c4.2,0.1,8.5,0.4,12.5,2  c1.4,0.6,2.9,1.4,3.5,2.9c0.6,1.7-0.6,3.2-1.8,4.2c-3,2.4-6.9,3.4-10.4,4.7c-26.4,9.4-52.8,18.8-79.2,28.2c-0.5,0.1-0.3,0.8-0.4,1.2  c0,9.7,0,19.4,0,29.2c34.7-12.5,69.4-24.9,104.1-37.4c5.7-2.1,11.5-3.9,16.9-6.7c5.1-2.6,10.2-5.5,14-9.8c2-2.3,3.4-5.3,3.3-8.4  C416,309.9,413.6,306.5,410.9,304z" fill="#FFFFFF" id="logo" />
            </>
            break
        case "pc":
            viewBoxSize = 389.981
            scale = 0.8
            innerViewBox = `${-(viewBoxSize) * (scale / 2)} ${-(viewBoxSize) * (scale / 2)} ${viewBoxSize * (1 + scale)} ${viewBoxSize * (1 + scale)}`
            colour = colour === "" ? "#ff9a00" : colour
            node = <>
                <circle cx="50%" cy="50%" fill={colour} id="ellipse" r="50%" />
                <svg viewBox={innerViewBox}>
                    <path fill="#FFFFFF" d="M145.407,248.988l-2.775,26.209c-0.224,2.115,0.46,4.225,1.883,5.806c1.422,1.581,3.449,2.483,5.576,2.483h79.301
    c2.126,0,4.153-0.903,5.576-2.483c1.422-1.581,2.106-3.691,1.883-5.806l-2.775-26.209h98.777c15.374,0,27.881-12.507,27.881-27.881
    V27.881C360.733,12.507,348.225,0,332.852,0H40.63C25.256,0,12.749,12.507,12.749,27.881v193.226
    c0,15.374,12.507,27.881,27.881,27.881H145.407z M221.055,268.487h-62.629l2.064-19.499h58.5L221.055,268.487z M40.63,15h292.222
    c7.103,0,12.881,5.778,12.881,12.881V197.99H27.749V27.881C27.749,20.778,33.527,15,40.63,15z M27.749,221.107v-8.117h317.983
    v8.117c0,7.103-5.778,12.881-12.881,12.881H40.63C33.527,233.988,27.749,228.21,27.749,221.107z"/>
                    <path fill="#FFFFFF" d="M332.645,333.876c1.36-2.24,1.453-5.027,0.244-7.352l-14.813-28.499c-1.291-2.483-3.856-4.041-6.655-4.041H62.061
    c-2.798,0-5.364,1.558-6.655,4.041l-14.813,28.499c-1.208,2.325-1.116,5.112,0.244,7.352s3.791,3.607,6.411,3.607h278.986
    C328.854,337.484,331.285,336.116,332.645,333.876z M59.599,322.484l7.016-13.499h240.251l7.017,13.499H59.599z"/>
                    <path fill="#FFFFFF" d="M345.733,350.982h-12c-17.368,0-31.499,14.13-31.499,31.499c0,4.142,3.358,7.5,7.5,7.5h59.997c4.142,0,7.5-3.358,7.5-7.5
    C377.232,365.113,363.102,350.982,345.733,350.982z M319.04,374.981c2.735-5.337,8.295-8.999,14.693-8.999h12
    c6.398,0,11.958,3.662,14.694,8.999H319.04z"/>
                </svg>
            </>
            break
        case "switch":
            colour = colour === "" ? "#CF201B" : colour
            node = <>
                <circle cx="256" cy="256" fill={colour} id="ellipse" r="256" />
                <path stroke="null" id="svg_2" fill="#FFFFFF" d="m177.486734,119.658875c-27.322701,4.900494 -49.315846,25.117121 -56.238122,51.704628c-2.511115,9.618383 -2.69551,15.254071 -2.388782,89.749696c0.121737,68.367024 0.183798,69.959744 1.347457,75.290493c6.310013,28.485166 26.526639,48.765048 55.319727,55.441464c3.736836,0.857527 8.514996,1.041325 39.268401,1.164255c31.79473,0.245263 35.103101,0.183202 35.960627,-0.674922c0.85693,-0.857527 0.918992,-11.823367 0.918992,-136.123575c0,-92.199346 -0.183202,-135.57218 -0.550798,-136.369435c-0.551395,-1.040728 -1.531255,-1.102193 -34.857837,-1.040728c-27.078034,0.062062 -35.225434,0.24586 -38.779665,0.858124l0,0z" />
                <path stroke="null" id="svg_3" fill={colour} d="m228.885827,256.027713l0,115.11029l-23.1574,-0.305535c-21.31882,-0.245263 -23.585269,-0.366403 -27.873499,-1.407728c-18.316581,-4.718486 -31.856195,-18.809495 -35.715961,-37.186944c-1.225124,-5.758021 -1.225124,-147.090011 -0.061465,-152.726296c3.430704,-15.989861 14.519474,-29.221553 29.405351,-35.163969c7.473671,-3.002239 10.96584,-3.369239 35.286899,-3.430704l21.992548,-0.061465l0,115.172351l0.123527,0z" />
                <path stroke="null" id="svg_4" fill="#FFFFFF" d="m182.387825,175.65233c-3.553037,0.673728 -9.004926,3.431301 -11.9457,5.942416c-5.942416,5.145757 -8.944058,12.497095 -8.454128,21.135022c0.245263,4.472626 0.48993,5.697749 2.327914,9.311655c2.69551,5.513354 6.677013,9.556918 12.19156,12.252429c3.798301,1.899449 4.778161,2.083247 9.74012,2.267045c4.471432,0.182605 6.064749,0 9.066988,-1.042519c12.313297,-4.165897 19.787565,-16.112194 17.642853,-28.424894c-2.388782,-14.518877 -16.295396,-24.319865 -30.569606,-21.441153z" />
                <path stroke="null" id="svg_5" fill="#FFFFFF" d="m279.303867,119.10748c-0.24407,0.183798 -0.365806,61.751476 -0.365806,136.920233c0,123.933805 0.060272,136.492365 0.917798,136.859365c1.531255,0.551395 45.639879,0.368193 51.092961,-0.245263c23.094742,-2.634045 43.495763,-16.662993 54.522472,-37.491883c1.408922,-2.69551 3.307774,-7.167539 4.288231,-9.924515c3.553634,-10.660305 3.492169,-7.841865 3.492169,-89.503836c0,-65.18218 -0.12114,-74.922897 -0.918992,-79.026732c-5.635091,-29.590343 -28.240499,-51.951085 -57.891711,-57.035377c-3.982696,-0.673728 -10.108313,-0.857527 -29.896475,-0.857527c-13.722816,-0.062062 -25.055656,0.121737 -25.240647,0.305535z" />
                <path stroke="null" id="svg_6" fill={colour} d="m340.015211,242.917758c8.88319,2.32851 16.111597,9.066391 19.113836,17.704318c1.898255,5.330749 1.837984,13.170824 -0.062659,18.073705c-3.490379,9.00433 -10.290918,15.314342 -19.051178,17.642256c-14.212746,3.676564 -29.34508,-4.899897 -33.450109,-18.99031c-1.225124,-4.289424 -1.163062,-11.579894 0.244667,-16.051923c4.228556,-13.906017 18.992697,-22.114882 33.205442,-18.378046l0,0z" />
            </>
            break
        case "xbox":
            colour = colour === "" ? "#7EB900" : colour
            node = <>
                <circle cx="256" cy="256" fill={colour} id="ellipse" r="256" />
                <path d="M259.2,99.2c-17.8-0.3-35.8,2.3-52.4,7.6c-14.5,4.7-27.2,9.7-28.1,11.2c-0.9,1.5,8.8,5.5,21.6,9  c12.8,3.5,30.1,10.4,38.3,15.2c18.7,11.1,18,11,32.6,2c16.7-10.3,58.4-23,68-20.7c4.3,1-2.9-3.3-15.9-9.7  C304.5,104.6,282.1,99.6,259.2,99.2z M360.1,135.2l-13.7,7.4c-7.6,4.1-22.3,15.1-32.5,24.6l-18.6,17.1l20.8,26  c43.1,53.6,66.9,99.6,67.3,130l0.2,14.5l10.9-16.3c16.8-25.1,22.8-50.8,21.3-90.6c-1.1-29.7-2.9-37.8-13.2-58.7  c-6.6-13.3-18.8-30.9-27.1-39.1L360.1,135.2L360.1,135.2z M155.6,137.5c-2.9-0.1-5.5,1.2-9.7,4c-11.7,7.7-33.3,40.5-42.5,64.6  c-14.8,38.9-6.7,98.8,18,135.1c9.8,14.3,11.9,15.8,8.8,5.8c-6.3-19.8,26.1-84.9,68.2-137l21.1-25.9l-19.7-17.5  c-10.8-9.6-25.2-20.6-32.2-24.3C161.8,139.1,158.5,137.6,155.6,137.5L155.6,137.5z M256,219l-26.5,23  c-47.1,40.7-90.2,95.7-90.2,115.4c0,14.7,42.4,41.9,80.4,51.6c40.5,10.3,97.3-0.8,132-25.7c28.8-20.7,29.6-28,8-60.7  c-13.4-20.2-73-82.1-91.7-95.1L256,219L256,219z" fill="#FFFFFF" id="logo" />
            </>
            break
        default:
            viewBoxSize = 58.189
            viewBox = `0 0 ${viewBoxSize} ${viewBoxSize}`
            scale = 0.5
            innerViewBox = `${-(viewBoxSize) * (scale / 2)} ${-(viewBoxSize) * (scale / 2)} ${viewBoxSize * (1 + scale)} ${viewBoxSize * (1 + scale)}`
            colour = colour === "" ? "#d67ff5" : colour
            node = <>
                <circle cx="50%" cy="50%" fill={colour} id="ellipse" r="50%" />
                <svg viewBox={innerViewBox}>
                    <path fill="#38454F" d="M29.095,39.268h9.446c1.701,0,3.346,0.609,4.638,1.716l9.128,7.828
c0.611,0.524,1.505,0.614,2.184,0.183c9.496-6.01-2.022-29.728-4.305-34.034c-0.242-0.457-0.641-2.936-1.126-3.113l-7.676-2.791
c-0.673-0.245-1.422-0.155-2.017,0.242c0,0-2.164,1.383-2.649,1.383h-8.425h-6.549c-0.485,0-2.921-1.383-2.921-1.383
c-0.596-0.397-1.345-0.487-2.017-0.242L9.13,11.848c-0.486,0.177-0.884,2.656-1.126,3.113C5.721,19.267-5.797,42.985,3.699,48.995
c0.68,0.43,1.574,0.34,2.184-0.183l9.128-7.828c1.292-1.107,2.937-1.716,4.638-1.716H29.095z"/>
                    <circle fill="#546A79" cx="36.786" cy="30.268" r="4" />
                    <circle fill="#546A79" cx="13.786" cy="20.268" r="4" />
                    <circle fill="#EBBA16" cx="43.786" cy="16.268" r="2" />
                    <circle fill="#7383BF" cx="39.786" cy="20.268" r="2" />
                    <circle fill="#D75A4A" cx="47.786" cy="20.268" r="2" />
                    <circle fill="#61B872" cx="43.786" cy="24.268" r="2" />
                    <path fill="#AFB6BB" d="M31.786,11.712c0-0.192-0.019-0.38-0.054-0.561c-0.035-0.181-0.111-0.338-0.215-0.469
c-1.171,0-2.08,0-2.479,0c-0.085,0.007-0.165,0.03-0.251,0.03c-0.087,0-0.167-0.022-0.251-0.03c-0.013,0-0.242,0-0.242,0h-2.247
c-0.088,0.114-0.158,0.245-0.192,0.4c-0.087,0.398-0.094,0.827,0,1.271c0.248,1.17,1.225,2.113,2.404,2.314
C30.148,14.989,31.786,13.542,31.786,11.712z"/>
                    <path fill="#546A79" d="M27.286,27.921H24v-3.286c0-0.395-0.32-0.714-0.714-0.714h-2.571c-0.395,0-0.714,0.32-0.714,0.714
v3.286h-3.286c-0.395,0-0.714,0.32-0.714,0.714v2.571c0,0.395,0.32,0.714,0.714,0.714H20v3.286c0,0.395,0.32,0.714,0.714,0.714
h2.571c0.395,0,0.714-0.32,0.714-0.714v-3.286h3.286c0.395,0,0.714-0.32,0.714-0.714v-2.571C28,28.241,27.68,27.921,27.286,27.921z"
                    />
                </svg>
            </>
            break
    }
    return <SvgIcon viewBox={viewBox} style={{ fontSize: fontSize }}>{node}</SvgIcon>

}