import React, { useEffect, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import { useCookies, withCookies } from 'react-cookie';
import { Chip, makeStyles, Menu, MenuItem, Popper, Typography, useTheme } from '@material-ui/core';
import User from '../entities/User';
import { fromJSON } from '../util/json-utils';
import { getCurrentUser, getLoginURL, getLogoutURL } from '../api/auth';

interface ILoginProps {
    top: number
}

export default function Login(props: ILoginProps) {
    const { top } = props
    const [cookies, setCookie, removeCookie] = useCookies();
    const [buttonText, setButtonText] = useState('Login')
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const theme = useTheme()

    const loginMenuOffset = useMemo(() => {
        if (anchorEl !== null)
            return top - anchorEl!.getBoundingClientRect().bottom
    }, [anchorEl, top])

    // const getUsername = () => {
    //     let userString = localStorage.getItem('user')
    //     let username
    //     if (userString) {
    //         let user = fromJSON(JSON.parse(userString), User)
    //         console.log('user :>> ', user);
    //         username = user.getUsername()
    //     }
    //     console.log('username :>> ', username);
    //     return username
    // }

    async function handleLogin() {
        console.log("Login")
        window.location.href = await getLoginURL()
    }

    const isLoggedIn = () => {
        let storedUser = localStorage.getItem('user')
        console.log('storedUser :>> ', storedUser);
        return storedUser != null
    }

    const updateLoginLabel = () => {
        setButtonText(getCurrentUser()?.getUsername() || 'Login')
    }

    useEffect(() => {
        console.log("init")
        let storageCallback = () => {
            console.log("storage event!")
            if (isLoggedIn()) updateLoginLabel()
        }

        window.addEventListener('storage', storageCallback)
        if (isLoggedIn()) updateLoginLabel()

        return () => {
            window.removeEventListener('storage', storageCallback)
        }
    }, [])



    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (isLoggedIn()) {
            setAnchorEl(event.currentTarget)
        }
        else {
            handleLogin()
        }
    };

    const handleLogout = () => {
        logout()
        handleClose()
    }

    const logout = async () => {
        console.log("Logout")
        // removeCookie('username')
        localStorage.removeItem('refresh_token')
        localStorage.removeItem('user')
        caches.delete('percentPlayed')
        let logoutURL = await getLogoutURL()
        console.log(`logout >> ${logoutURL}`)
        window.location.href = logoutURL
    }

    const handleClose = () => {
        setAnchorEl(null)
    };

    return (<>
        <Chip style={{ color: "inherit", borderColor: "inherit" }} label={<Typography>{buttonText}</Typography>} variant="outlined" onClick={handleClick} />
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            style={{ zIndex: 2000, top: loginMenuOffset }}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{ style: { backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText } }}
        >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>My account</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
    </>)

}
