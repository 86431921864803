import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box, Container, Grid } from '@material-ui/core';
import { ReviewPreviewCard } from '../../ui/ReviewCard';
import Review from "../../entities/Review"
import { ObjectStore } from '../../util/json-utils';
import { get } from '../../api/request';


const font = "'Roboto Mono', monospace"
// let reviews = getObjectStore<Review>(review_data.data, Review)
// reviews = Object.keys(reviews).map(id=>{initReviewFromObject(reviews[id])})

// console.log('reviews :>> ', reviews);
// const reviews: Review[] = JSON.parse(JSON.stringify(review_data.data)).map((review: any) => new Review(review.id, review.props))

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 0
    }
}));

export default function ReviewsPage() {
    const classes = useStyles()
    const [reviews, setReviews] = useState<ObjectStore<Review> | null>()

    useEffect(() => {
        async function init() {
            let reviews = await get<Review>(Review, "data/reviews", false)
            setReviews(reviews)
        }
        init()
    }, [])

    return (
        <Container maxWidth="xl">
            <Box display="flex" alignItems="baseline">
                <Typography variant="h3" style={{ color: "white", fontFamily: font }}>iSnack</Typography>
                <Typography variant="h6" style={{ color: "white", fontFamily: font, marginLeft: 0 }}>_Andrew</Typography>
            </Box>

            {reviews &&
                <Grid className={classes.root} container spacing={4}>
                    {Object.keys(reviews).map(id => {
                        console.log('reviews[id].props :>> ', reviews[id].toString());
                        return (
                            <Grid key={id} item xs={12} md={6} >
                                <ReviewPreviewCard review={reviews[id]} />
                            </Grid>
                        )
                    })}
                </Grid>
            }

        </Container>

    )
}