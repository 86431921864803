import { FormControlLabel, Icon, IconButton, IconButtonClassKey, List, ListItem, ListItemIcon, ListItemText, makeStyles, SwipeableDrawer, Typography, useTheme } from '@material-ui/core'
import React from 'react'
import AddIcon from '@material-ui/icons/Add';
import ReviewPageIcon from '@material-ui/icons/Dashboard';
import useReactRouter from 'use-react-router';
import zIndex from '@material-ui/core/styles/zIndex';



interface IMenuDrawerProps {
    open: boolean
    setOpen: (open: boolean) => void
    top: number
}

interface MenuButton {
    label: string
    icon: any
    path: string
}

function createMenuButton(label: string, icon: any, path: string): MenuButton {
    return { label: label, icon: icon, path: path }
}

const menuButtons: MenuButton[] = [
    createMenuButton("Reviews", ReviewPageIcon, "/reviews"),
    createMenuButton("Create", AddIcon, "/create"),
]

export default function MenuDrawer(props: IMenuDrawerProps) {
    const { open, setOpen, top } = props
    const theme = useTheme()
    const router = useReactRouter()
    const proc = process as any
    const iOS = proc.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
    // const iOS = true

    const useStyles = makeStyles({
        drawer: {
            color: "transparent",
            // top: top,
            // zIndex: 500
        },
        backdrop: {
            invisible: true,
            backgroundColor: "transparent",
            // top: top
        },
        paper: {
            backgroundColor: theme.palette.primary.main,
            top: top,
            // height: `calc(100%-${top}px)`,
            width: 200
        },
        modal: {
            // top: top,
            // zIndex: 1400
        },
        menuButtonText: {
            color: theme.palette.primary.contrastText,
            marginLeft: 0
        },
        menuButtonIcon: {
            color: theme.palette.primary.contrastText,
            minWidth: 40
        },

    })
    const classes = useStyles()

    function handleClick(event: any) {
        if (router.location.pathname !== event.currentTarget.dataset.path) {
            router.history.push(event.currentTarget.dataset.path)
        }
    }

    return (
        <SwipeableDrawer
            anchor="left"
            variant="temporary"
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            open={open}
            className={classes.drawer}
            ModalProps={{ className: classes.modal }}
            BackdropProps={{ className: classes.backdrop }}
            PaperProps={{ className: classes.paper }}
            disableBackdropTransition={!iOS}
            disableSwipeToOpen={false}
            disableDiscovery={iOS}
            swipeAreaWidth={50}

        >
            <div
                role="presentation"
                onClick={() => setOpen(false)}
                onKeyDown={() => setOpen(false)}
            >
                {/* <Typography>{iOS ? "iOS" : ""}</Typography> */}
                <List >
                    {menuButtons.map(mb => {
                        return <ListItem key={mb.path} button onClick={handleClick} data-path={mb.path}>
                            <ListItemIcon className={classes.menuButtonIcon}><mb.icon /></ListItemIcon>
                            <ListItemText primary={mb.label} className={classes.menuButtonText} />
                        </ListItem>
                    })}
                </List>
            </div>

        </SwipeableDrawer >
    )
}
