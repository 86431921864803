import { Container, Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { ReviewCard } from '../../ui/ReviewCard';
import Review from '../../entities/Review';
import ReviewUpdates from '../../ui/ReviewUpdates';
import { get } from '../../api/request';
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 0
    }
}));

export default function SingleReviewPage() {
    const classes = useStyles()
    const { id } = useParams() as any
    const theme = useTheme();
    const contained = useMediaQuery(theme.breakpoints.down('sm'));
    const [edit, setEdit] = useState(false)
    const [review, setReview] = useState<Review | null>()

    useEffect(() => {
        get<Review>(Review, `data/reviews/${id}`, false)
            .then(reviews => setReview(reviews[id]))
    }, [id])

    return (
        <>
            {review &&
                <Container maxWidth="xl">
                    <Grid className={classes.root} container spacing={4}>
                        <Grid item xs={12} md={8} >
                            <ReviewCard review={review} containsUpdate={contained} edit={edit} setEdit={setEdit} />
                        </Grid>
                        {!contained && <Grid item md={4}
                        >
                            <ReviewUpdates review={review} />
                        </Grid>}
                    </Grid>
                </Container>
            }
        </>
    )
}
