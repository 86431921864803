import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Avatar, Box, Card, CardContent, CardHeader, CardMedia, CircularProgress, Divider, IconButton, LinearProgress, List, ListItem, Snackbar, SvgIcon, TextareaAutosize, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Review from '../entities/Review';
import Graph from './Graph';
import Game from '../entities/Game';
import moment from 'moment';
import useReactRouter from 'use-react-router';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import ReviewUpdates from './ReviewUpdates';
import ReactMarkdown from 'react-markdown'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { update } from '../api/request';
import { Alert } from '@material-ui/lab';
import { getCurrentUser } from '../api/auth';
import Platform from '../entities/Platform';


interface IReviewCardProps {
    review: Review
    containsUpdate?: boolean
    edit: boolean
    setEdit(edit: boolean): void
}

export function ReviewCard(props: IReviewCardProps) {

    const { review, containsUpdate, edit, setEdit } = props
    const [height, setHeight] = useState(0)
    const [top, setTop] = useState(0)
    const [reviewText, setReviewText] = useState<string>(review.getFullReview())

    const measureRef = useCallback(
        (node: HTMLDivElement) => {
            if (node !== null) {
                console.log('node.getBoundingClientRect().top :>> ', node.getBoundingClientRect().top);
                setTop(node.getBoundingClientRect().top)
                setHeight(window.innerHeight - node.getBoundingClientRect().top)
            }
        },
        [],
    )

    useEffect(() => {
        if (!edit) {
            setReviewText(review.getFullReview())
        }
    }, [edit, review])

    const handleSave = async () => {
        review.setFullReview(reviewText)
        console.log(' saving.. ');
        await update(Review, `data/reviews`, review)
        console.log(' saved.. ');
        (await caches.open('percentPlayed')).delete(`/data/reviews/${review.id}`)
        setTimeout(() => window.location.reload(), 500)
    }

    window.addEventListener('resize', (ev) => {
        setHeight(window.innerHeight - top)
    })

    return (
        <Card>
            <ReviewCardHeader review={review} edit={edit} setEdit={setEdit} handleSave={handleSave} />
            <div ref={measureRef} style={{ overflow: "auto" }}>
                <CardMedia style={{ height: "40%", minHeight: '250px' }}>
                    <Graph review={review!} />
                </CardMedia>
                {containsUpdate &&
                    <CardContent >
                        <ReviewUpdates review={review} contained />
                    </CardContent>
                }
                <CardContent>
                    {edit ?
                        <ReactQuill theme="snow" value={reviewText} onChange={content => setReviewText(content)} modules={{ clipboard: { matchVisual: false } }} /> :
                        <Typography
                            style={{

                                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
                            } as React.CSSProperties}
                            dangerouslySetInnerHTML={{ __html: review.getFullReview() }}
                        >
                        </Typography>
                    }
                </CardContent>
            </div>
        </Card>
    )
}

interface IReviewPreviewCardProps {
    review?: Review
}

export function ReviewPreviewCard(props: IReviewPreviewCardProps) {
    const { review } = props

    function getSimpleCard() {
        return (
            <Card style={{ height: 400 }}>
                <ReviewCardHeader review={review} edit={false} />
                <CardMedia style={{ height: "38%" }}>
                    <Graph review={review!} />
                </CardMedia>
                {review!.getSegments().length > 0 &&
                    <CardContent >
                        <CommentsCardContent review={review!} />
                    </CardContent>
                }
            </Card >
        )
    }

    function getTombstone() {
        return (
            <Card style={{ height: 400 }}>
                <ReviewCardHeader review={review} edit={false} />
                <CardContent style={{ height: "50%" }}>
                    <Box style={{ alignItems: "center" }}>
                        <LinearProgress />
                    </Box>
                </CardContent>
                <CardContent>
                    <Box >
                        <LinearProgress />
                    </Box>
                </CardContent>
            </Card >
        )
    }

    if (review) {
        return getSimpleCard()
    }
    else {
        return getTombstone()
    }
}

interface IReviewCardHeaderProps {
    review?: Review
    edit: boolean | undefined
    setEdit?(edit: boolean): void
    handleSave?(): Promise<void>
}

function ReviewCardHeader(props: IReviewCardHeaderProps) {
    const { review, setEdit, edit, handleSave } = props
    const theme = useTheme()
    const [game, setGame] = useState<Game>()
    let title = <><Typography variant="h5" ><strong>Loading...</strong></Typography></>
    const [percentPlayed, setPercentPlayed] = useState(1)
    const durationPlayed = review?.getDurationPlayed()
    const subheader = <Typography >{percentPlayed}% played{durationPlayed !== 0 ? ` - ${moment.duration(durationPlayed, "minute").humanize()}` : ""}</Typography>
    let platform: Platform | undefined
    const router = useReactRouter()
    const [saving, setSaving] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [saveMessage, setSaveMessage] = useState('')

    useEffect(() => {
        async function getGame() {
            if (review) {
                let game = await review.getGame()
                console.log('game :>> ', game);
                setGame(game)
                setPercentPlayed(await review?.getPercentPlayed())
            }
        }
        getGame()
        console.log('review :>> ', review);
    }, [review])

    useEffect(() => {
        console.log(game?.getName(), 'percentPlayed :>> ', percentPlayed);
    }, [percentPlayed])

    function handleClick(e: any) {
        if (router.location.pathname === `/reviews`) {
            router.history.push(`/review/${review?.id}`)
        }
    }

    const handleSaveClick = async () => {
        if (handleSave) {
            try {
                setSaving(true)
                await handleSave()
                setSaving(false)
                setSaveMessage("Review saved")
                setOpenSnackbar(true)
            }
            catch (err) {
                setSaveMessage("Error saving review")
            }
        }
    }

    const handleSnackbarClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    let action =
        setEdit ?
            <>
                {edit && <IconButton onClick={() => handleSaveClick()}>{saving ? <CircularProgress size={24} /> : <SaveIcon />}</IconButton>}
                {review?.isEditable() && <IconButton onClick={() => setEdit(!edit)}>{edit ? <CloseIcon /> : <EditIcon />}</IconButton>}
            </>
            : null


    if (game) {
        platform = review?.getPlatform()
        title = <><Typography variant="h5" ><strong>{game.getName()}</strong></Typography></>

    }

    function getCard() {
        return (
            <React.Fragment>
                <CardHeader
                    title={title}
                    subheader={subheader}
                    avatar={<GameAvatar platform={platform!} />}
                    action={action}
                    onClick={handleClick}
                    style={{
                        background: `linear-gradient(to right, ${theme.palette.secondary.main} ${percentPlayed}%, ${theme.palette.secondary.dark} ${percentPlayed}% 100% )`,
                        // backgroundSize: '200% 100%',
                        // backgroundPosition: 'right bottom',
                        // transition: 'all 2s ease'
                    }}
                ></CardHeader>
                <LinearProgress variant="determinate" value={percentPlayed} style={{ height: 10 }} />
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={handleSnackbarClose}
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                >
                    <Alert severity={saveMessage.toLowerCase().includes('error') ? 'error' : 'success'} variant="filled">
                        {saveMessage}
                    </Alert>
                </Snackbar>

            </React.Fragment >
        )
    }

    function getTombstone() {
        return (
            <React.Fragment>
                <CardHeader
                    title={<><LinearProgress style={{ width: "50%" }} /><br></br></>}
                    subheader={<LinearProgress style={{ width: "30%" }} />}
                    avatar={<Avatar></Avatar>}
                    style={{ background: theme.palette.secondary.dark }}
                />
                <LinearProgress style={{ height: 10 }} variant="determinate" value={0} />
            </React.Fragment>
        )
    }

    if (review && game) {
        return getCard()
    }
    else {
        return getTombstone()
    }


}

interface IGameAvatarProps {
    platform: Platform
}

function GameAvatar(props: IGameAvatarProps) {
    const { platform } = props

    function getPlatformInitials(platform: Platform) {
        let initials = "N/A"
        switch (platform.getName()) {
            case "playstation":
                initials = "PS"
                break
            case "xbox":
                initials = "XB"
                break
            case "switch":
                initials = "SW"
                break
            case "pc":
                initials = "PC"
                break
            default:
                break
        }
        return initials
    }

    return platform && platform.getPlatformIcon()
}

interface ICommentsCardContentProps {
    review: Review
}

function CommentsCardContent(props: ICommentsCardContentProps) {
    const { review } = props
    const segments = review.getSegments()
    const comments = segments.map(seg => seg.getComments())
    const latest_seg = segments[segments.length - 1]

    return (
        <Box
            component="div"
        >
            <Box display="flex">
                <Typography variant="h6" >Latest</Typography>
            </Box>

            <Typography variant="body1" style={{ textOverflow: "ellipsis" }}>
                <strong>{latest_seg.getChapterName()} - {latest_seg.getDate().fromNow()} - {latest_seg.getDate().format("MMM Do YYYY")}</strong>
            </Typography>
            <Box >
                <div
                    style={{
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
                    } as React.CSSProperties}
                >
                    <ReactMarkdown>
                        {latest_seg.getComments()}
                    </ReactMarkdown>
                </div>

            </Box>

        </Box >
    )
}