import { Box, Card, CardContent, CardHeader, IconButton, LinearProgress, List, ListItem, Typography, useTheme } from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import Review from '../entities/Review'
import ChatIcon from '@material-ui/icons/Chat';

interface IReviewUpdatesProps {
    review: Review
    contained?: boolean
}

export default function ReviewUpdates(props: IReviewUpdatesProps) {
    const { review, contained } = props
    const theme = useTheme()
    const segments = review.getSegments()
    // console.log('segments :>> ', JSON.stringify(segments));
    // console.log('segments :>> ', JSON.stringify(segments));

    const comments = segments.map(seg => seg.getComments())
    const latest_seg = segments[segments.length - 1]
    const [height, setHeight] = useState(0)
    const [top, setTop] = useState(0)
    const measureRef = useCallback(
        (node: HTMLDivElement) => {
            if (node !== null) {
                console.log('node.getBoundingClientRect().top :>> ', node.getBoundingClientRect().top);
                setTop(node.getBoundingClientRect().top)
                setHeight(window.innerHeight - node.getBoundingClientRect().top)
            }
        },
        [],
    )

    window.addEventListener('resize', (ev) => {
        setHeight(window.innerHeight - top)
    })

    return (
        <Card  >
            <CardHeader
                title={"Update Feed"}
                style={{ backgroundColor: theme.palette.secondary.main }}
                action={<IconButton><ChatIcon /></IconButton>}
            />
            <LinearProgress variant="determinate" value={100} style={{ height: 10 }} />
            <div ref={measureRef} style={{ overflow: "auto", maxHeight: (contained ? 150 : height) }} >
                <ReviewUpdatesContent review={review} />
            </div>

        </Card>
    )
}

interface IReviewUpdatesContentProps {
    review: Review
}

function ReviewUpdatesContent(props: IReviewUpdatesContentProps) {

    const { review } = props
    const segments = [...review.getSegments(), ...review.getSegments(), ...review.getSegments()]
    // console.log('segments :>> ', JSON.stringify(segments));
    // console.log('segments :>> ', JSON.stringify(segments));
    const comments = segments.map(seg => seg.getComments())
    const latest_seg = segments[segments.length - 1]

    return (
        <CardContent style={{ overflow: "auto" }}>
            <List >
                {segments.map(seg => {
                    // console.log('seg.getID() :>> ', seg.id);
                    return <ListItem key={seg.id}>
                        <Box>
                            <Typography variant="body1" >
                                <strong>{seg.getChapterName()} - {seg.getDate().fromNow()} - {seg.getDate().format("MMM Do YYYY")}</strong>
                            </Typography>
                            <Typography style={{ fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }} >
                                <ReactMarkdown>{seg.getComments()}</ReactMarkdown>
                            </Typography>

                        </Box>
                        {/* <strong>{seg.getChapterName()} - {seg.getDate().fromNow()} - {seg.getDate().format("MMM Do YYYY")}</strong> */}

                    </ListItem>
                }).reverse()}
            </List>
        </CardContent>
    )
}
