import { Box, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react'
import Review from '../../entities/Review';
import CreateCard from '../../ui/CreateCard';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 0
    }
}));

export default function Create() {
    const classes = useStyles()
    const [review, setReview] = useState<Review>()

    return (
        <Container maxWidth="xl">
            {/* <Box display="flex" alignItems="baseline">
                <Typography variant="h3" style={{ color: "white", }}>iSnack</Typography>
                <Typography variant="h6" style={{ color: "white", marginLeft: 0 }}>_Andrew</Typography>
            </Box> */}

            <Grid container className={classes.root} spacing={4}>
                <Grid item xs={12} md={12}>
                    <CreateCard></CreateCard>
                </Grid>
            </Grid>
            {/* <Grid className={classes.root} container spacing={4}>
                <Grid item xs={12} md={6} >
                    <ReviewCard review={review} />
                </Grid>
            </Grid> */}
        </Container>
    )
}
