import moment from "moment"
import { PropsObject } from "./Base"

interface IReviewSegmentProps {
    overall: number
    comments: string
    date: Date
    customScores?: { [name: string]: number }
    types: ReviewSegmentType[]
    chapterName?: string
    duration?: number
}

type ReviewSegmentType = "chapter" | "duration"

export default class ReviewSegment extends PropsObject<ReviewSegment, IReviewSegmentProps> {

    constructor(props: IReviewSegmentProps, _id?: string) {
        super(props, _id)
        if (!("chapterName" in props)) {
            // console.log('setting chapt')
            this.props.chapterName = _id
        }
    }

    getOverallScore() {
        return this.props.overall
    }

    getComments() {
        return this.props.comments
    }

    getTypes() {
        return this.props.types
    }

    getChapterName() {
        if (this.isType("chapter")) {
            return this.props.chapterName
        }
    }

    getDate() {
        return moment(this.props.date)
    }

    getCustomScores() {
        let ret: { [name: string]: number } = {}
        if (this.props.customScores)
            ret = this.props.customScores
        return ret
    }

    getDuration() {
        if ("duration" in this.props)
            return this.props.duration
    }

    isType(type: ReviewSegmentType) {
        return this.props.types.includes(type)
    }

    static getScoreColour(score_name: string) {
        let lower_score_name = score_name.toLowerCase()
        let colour = "black"
        switch (lower_score_name) {
            case "overall":
                colour = "green"
                break
            case "story":
                colour = "blue"
                break
            case "gameplay":
                colour = "red"
                break
            case "innovation":
                colour = "orange"
                break
            default:
                break
        }
        return colour
    }
}