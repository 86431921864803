import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import useReactRouter from 'use-react-router';
import Login from './Login';

interface IHeaderProps {
    onMenuClick(): void,
    height: any
}

export default function Header(props: IHeaderProps) {
    const { onMenuClick, height } = props
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            marginBottom: 10,
        },
        appBar: {
            height: height,
            zIndex: 1400
        },
        toolbar: {
            alignItems: "center",
            height: height
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
            overflow: "auto",
            textOverflow: "ellipsis"
        },
    }));
    const classes = useStyles();
    const router = useReactRouter()
    let path = router.location.pathname

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.toolbar} >
                    <IconButton edge="start" className={classes.menuButton} onClick={onMenuClick} color="inherit" aria-label="menu">
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {path !== "/" ? `${path}` : ""}
                    </Typography>
                    <Login top={height} />
                </Toolbar>
            </AppBar>
            <Toolbar className={classes.toolbar}></Toolbar>
        </div >
    )
}