
import { getCurrentUser } from "../api/auth"
import { get } from "../api/request"
import { fromJSON } from "../util/json-utils"
import { PropsObject } from "./Base"
import Game from "./Game"
import Platform from "./Platform"
import ReviewSegment from "./ReviewSegment"

interface IReviewProps {
    game: string,
    gameObj?: Game
    segments: ReviewSegment[]
    fullReview?: string
    platform?: Platform
    customScoreNames?: string[]
    userID: string

}

export default class Review extends PropsObject<Review, IReviewProps>{

    constructor(props: IReviewProps, _id?: string) {
        super(props, _id)
        let reviewSegs = props.segments.map((seg: any) => new ReviewSegment(seg.props, seg.id))
            .sort((a, b) => a.getDate().valueOf() - b.getDate().valueOf())
        this.props.segments = reviewSegs
    }

    isEditable() {
        let user = getCurrentUser()
        if (user) {
            return this.getUserID() === user.getUsername()
        }
        else {
            return false
        }
    }

    getUserID() {
        return this.props.userID
    }

    async getGame() {
        return await this.fetchGame(this.props.game)
    }

    hasGame() {
        console.log('this.props.game :>> ', this.props.game);
        return this.props.game != null && this.props.game !== undefined && this.props.game.trim() !== ''
    }

    async setGame(game: string | Game) {
        if (game instanceof Game && game.id !== undefined) {
            this.props.game = game.id
        }
        else if (typeof (game) === 'string') {
            this.props.game = game
            let gameObj = await this.fetchGame(game)
            if (!gameObj) {
                throw Error('No game found!')
            }
            this.setPlatform(gameObj.getPlatforms()[0])
        }
    }

    private async fetchGame(id: string) {
        let games = await get<Game>(Game, `data/gameDB/${id}`, false)
        console.log('games :>> ', games);
        return games[id] || null
    }

    setFullReview(reviewText: string) {
        this.props.fullReview = reviewText
    }

    getFullReview() {
        return this.props.fullReview || ""
    }

    getPlatform() {
        return fromJSON(this.props.platform, Platform)
    }

    setPlatform(platform: Platform) {
        this.props.platform = platform
    }

    getSegments() {
        return this.props.segments || []
    }

    setCustomScoreNames(scores: string[]) {
        this.props.customScoreNames = scores
    }

    getCustomScoreNames() {
        let ret: string[] = []
        if (this.props.customScoreNames) {
            ret = this.props.customScoreNames
        }
        return ret
    }

    async getPercentPlayed() {
        let game = await this.getGame()
        let chapters: string[] = game.getIntervals().chapters
        console.log('chapters :>> ', chapters);
        let totalChapters = chapters.length
        let chaptersReviewed = Object.values(this.getSegments()).reduce((count, seg) => {
            let chapterName = seg.getChapterName()!
            console.log('chapter_name :>> ', chapterName);
            if (chapters.includes(chapterName)) {
                return count + 1
            }
            else {
                return count
            }
        }, 0)
        console.log('total_chapters :>> ', totalChapters);
        console.log('chapters_reviewed :>> ', chaptersReviewed);
        let percentPlayed = Math.round(chaptersReviewed / totalChapters * 100)
        return percentPlayed
    }

    getDurationPlayed() {
        return Object.values(this.getSegments()).reduce((total, seg) => {
            let duration = seg.getDuration()
            if (duration) {
                return total + duration
            }
            else {
                return total
            }
        }, 0)
    }

    getScoreChanges() {
        let lastScores = this.getSegments()
    }


}