import React, { useEffect, useState } from 'react';
import './App.css';
import Header from './ui/Header';
import Reviews from './pages/Reviews'
import SingleReview from './pages/SingleReview'
import Create from './pages/Create'
import { BrowserRouter, HashRouter, Switch, Route, Redirect, withRouter, RedirectProps, RouteComponentProps } from 'react-router-dom';
import MenuDrawer from './ui/MenuDrawer';
import SiteFrame from './ui/SiteFrame';
import useRouter from 'use-react-router';
import PostLoginPage from './pages/PostLogin';
import { getCurrentUser } from './api/auth';
import User from './entities/User';
function App() {

    let mainPages = <BrowserRouter >
        <SiteFrame />
        <div style={{ top: 300 }}>
            <Switch >
                <Route exact path="/"><Redirect to="/reviews" /></Route>
                <Route exact path="/postlogin" render={(props: RouteComponentProps) => (<PostLoginPage routerProps={props} />)} />
                <Route exact path='/reviews' render={(props: any) => (<Reviews />)} />
                <Route exact path='/review/:id' render={(props: any) => (<SingleReview />)} />
                <Route exact path='/create' render={(props: any) => (<Create />)} />
                {/* <Route exact path='*' render={(props:any) => (<NotFound />)} /> */}
            </Switch>
        </div>

    </BrowserRouter >

    return (
        <>
            {mainPages}
        </>
    );
}

function ProtectedPage(props: any) {
    let user: User | null = null
    try {
        user = getCurrentUser()!
    }
    catch (err) {
        console.log('user not logged in');
    }
    finally {
        return user ? props.children : <Redirect to={{ pathname: "/login", search: "redirect=" + props.location.pathname, state: { from: props.location } }} />
    }
}

export default App;
