import React, { useState } from 'react'
import Header from './Header'
import MenuDrawer from './MenuDrawer'

export default function SiteFrame() {
    const [openDrawer, setOpenDrawer] = useState(false)
    const header_height = 65
    return (
        <>
            <Header onMenuClick={() => setOpenDrawer(openDrawer => !openDrawer)} height={header_height} />
            <MenuDrawer open={openDrawer} setOpen={setOpenDrawer} top={header_height} />
        </>
    )
}
