import { PropsObject } from "./Base";

export { }

interface IUser {
    username: string
}

export default class User extends PropsObject<User, IUser>{
    getUsername(): string {
        return this.props.username
    }
}