export function fromJSON<T>(jsonObj: any, type: new (props: any, id: string) => T) {
    if (jsonObj) {
        if ("props" in jsonObj && "_id" in jsonObj)
            return new type(jsonObj.props, String(jsonObj._id))
        else {
            let { id, ...props } = jsonObj
            return new type(props, String(id))
        }
    }
    else {
        return undefined
    }
}

export type ObjectStore<T> = { [id: string]: T }

export function getObjectStore<T>(data: any, type: new (props: any, id: string) => T): ObjectStore<T> {
    let store: ObjectStore<T> = {}
    Object.keys(data).forEach(id => {
        store[id] = new type(data[id].props, id)
    })
    return store
}

export function getObjectStoreFromArray<T>(data: any, type: new (props: any, id: string) => T): ObjectStore<T> {
    let store: ObjectStore<T> = {}
    let tempData = [...data]
    tempData.forEach((obj: any) => {
        store[obj.id] = new type(obj.props, obj.id)
    })
    return store
}

// export function getInstanceArray<T>(data: any, type: new (props: any, id: string) => T): T[] {
//     let array: T[] = []
//     let tempData = [...data]
//     tempData.forEach((obj: any) => {
//         array.push(new type(obj.props, obj.id))
//     })
//     return array
// }