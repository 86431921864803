import { getPlatformIcon } from "../util/svg";
import { PropsObject } from "./Base";
import Game from "./Game";

export type PlatformType = "pc" | "playstation" | "xbox" | "switch"

interface IPlatformProps {
    name: PlatformType
}

export default class Platform extends PropsObject<Game, IPlatformProps> {
    getName() {
        return this.props.name
    }

    getPlatformIcon(fontSize?: any, greyed?: boolean) {
        return getPlatformIcon(this.getName(), fontSize, greyed)
    }
}


