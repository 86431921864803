import { Box, Button, Card, CardActions, CardContent, FormControl, IconButton, Step, StepLabel, Stepper, Input, InputLabel, List, ListItem, Slide, TextField, Tooltip, Typography, useTheme, Grid } from '@material-ui/core'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import React, { useEffect, useState } from 'react'
import Game from '../entities/Game'
import Review from '../entities/Review'
import { ObjectStore } from '../util/json-utils'
import { toTitleCase } from '../util/text-utils'
import { get, post } from '../api/request'
import { getCurrentUser } from '../api/auth'
import Platform from '../entities/Platform'

export default function CreateCard() {
    enum ReviewCreationStages {
        "GAME" = 0,
        "SCORE",
        "CREATE"
    }

    const stageContent = [
        {
            label: "Game and Platform"
        },
        {
            label: "Custom Scores"
        },
        {
            label: "Create"
        }
    ]
    const [review, setReview] = useState<Review>()
    const [game, setGame] = useState<Game>()
    const [stage, setStage] = useState<number>(0)
    const [games, setGames] = useState<ObjectStore<Game>>({})
    const [tempGames, setTempGames] = useState<ObjectStore<Game>>()
    const theme = useTheme()
    let useCache = false

    function handleGameChange(event: any, game: Game | null) {
        setReview(prev => {
            let newReview = prev?.clone()
            if (game && game.id) {
                if (newReview === undefined) newReview = new Review({ game: game.id, segments: [], userID: getCurrentUser()?.getUsername() || "" })
                newReview?.setGame(game.id)
                setGame(game)
            }
            else {
                return undefined
            }
            return newReview
        })
    }

    function handleGameSearchUpdate(event: any, gameString: string | null) {
        console.log('gameString :>> ', gameString);
        if (gameString && gameString !== '') {
            get<Game>(Game, `data/gameDB?search=${gameString}`, undefined, true)
                .then(games => {
                    console.log(games)
                    setGames(games)
                })

            console.log('updating game search')
        }
        else {
            console.log("clearing")
            setGames({})
        }
    }

    function handlePlatformChange(platform: Platform) {
        setReview(prev => {
            let newReview = prev?.clone()
            if (newReview) {
                newReview.setPlatform(platform)
            }
            return newReview
        })
    }

    function handleCustomScoresChange(scores: string[]) {
        setReview(prev => {
            let newReview = prev?.clone()
            if (newReview) {
                newReview.setCustomScoreNames(scores)
            }
            return newReview
        })
    }

    function handleNextClick() {
        setStage(stage => stage + 1)
    }

    function handleBackClick() {
        setStage(stage => stage - 1)
    }

    async function handleCreate() {
        if (review) {
            let retReview = await post<Review>(Review, 'data/reviews', { body: review.toString() })
            console.log(`created: ${Object.values(retReview)}`)
            if (Object.values(retReview).length === 1 && retReview[0].id) {
                return retReview
            }
        }
    }

    return (
        <>

            {games && <Card>
                <Stepper activeStep={stage} alternativeLabel>
                    {stageContent.map(step => {
                        return (
                            <Step key={step.label} >
                                <StepLabel >{step.label}</StepLabel>
                            </Step>
                        )
                    })}
                </Stepper>
                {stage === ReviewCreationStages.GAME &&
                    <GameSelectCardContent
                        review={review}
                        game={game}
                        updateGame={handleGameChange}
                        updateGames={handleGameSearchUpdate}
                        updatePlatform={handlePlatformChange}
                        games={games}
                    />
                }
                {review && stage === ReviewCreationStages.SCORE &&
                    <ScoreSelectCardContent
                        review={review}
                        updateCustomScoreNames={handleCustomScoresChange}
                    />
                }
                {stage === ReviewCreationStages.CREATE &&
                    <Typography>Create Review?</Typography>
                }
                <CardActions style={{ display: "flex", justifyContent: "center" }}>
                    {stage !== 0 && <Button variant="outlined" color="primary" onClick={handleBackClick}>Back</Button>}
                    {stage !== ReviewCreationStages.CREATE ?
                        <Button variant="contained" color="primary" disabled={!(review?.hasGame())} onClick={handleNextClick}>Next</Button> :
                        <Button variant="contained" color="primary" onClick={handleCreate}>Create</Button>
                    }
                </CardActions>
                <CardContent>
                    <pre>
                        {JSON.stringify(review, null, 2)}
                    </pre>
                </CardContent>
            </Card>}
        </>
    )
}

interface IGameSelectCardContentProps {
    review: Review | undefined
    game: Game | undefined
    updateGame: (e: any, game: Game | null) => void
    updateGames: (e: any, gameString: string | null) => void
    updatePlatform: (platform: Platform) => void
    games: ObjectStore<Game>
}

function GameSelectCardContent(props: IGameSelectCardContentProps) {

    const {
        review,
        game,
        updatePlatform,
        updateGame,
        updateGames,
        games
    } = props

    let isTimerRunning = false
    let callbackTimer: number | undefined
    const updateCallback = (e: any, val: string) => {
        if (val === "") updateGames(e, val)
        return setTimeout(() => {
            updateGames(e, val)
            isTimerRunning = false
        }, 250) as any as number
    }

    return (
        <>
            <CardContent>
                <Autocomplete
                    options={Object.values(games).sort((a, b) => a.getName().localeCompare(b.getName()))}
                    getOptionLabel={option => option.getName()}
                    getOptionSelected={(option, value) => option.id === value.id}
                    value={game || null}
                    onChange={updateGame}
                    onInputChange={(e, val) => {
                        if (callbackTimer && (isTimerRunning || val === '')) {
                            clearTimeout(callbackTimer)
                            callbackTimer = updateCallback(e, val)
                        }
                        else {
                            isTimerRunning = true
                            callbackTimer = updateCallback(e, val)
                        }
                    }}
                    renderInput={(params) => <TextField {...params} label="Game" variant="outlined" placeholder="Search for a game" />}
                ></Autocomplete>
            </CardContent>
            <CardContent >
                {game && review && <PlatformSelect review={review} game={game} updatePlatform={updatePlatform} />}
            </CardContent>
        </>
    )
}

interface IPlatformSelectProps {
    review: Review
    game: Game
    updatePlatform: (platform: Platform) => void
}

function PlatformSelect(props: IPlatformSelectProps) {

    const { review, updatePlatform, game } = props
    const platforms = game.getPlatforms()
    const [platform, setPlatform] = useState(review.getPlatform() ? review.getPlatform() : platforms[0])

    function handlePlatformClick(platform: Platform) {
        updatePlatform(platform)
    }

    useEffect(() => {
        updatePlatform(platform!)
    }, [])

    useEffect(() => {
        setPlatform(review.getPlatform() ? review.getPlatform() : platforms[0])
    }, [review, platforms])

    return (<>
        <InputLabel shrink style={{ marginBottom: 15 }}>Select Platform</InputLabel>
        {platforms.map(pf => {
            let selected = pf.id === platform.id
            return <Tooltip key={pf.id} title={toTitleCase(pf.getName())} arrow>
                <IconButton
                    onClick={(e) => handlePlatformClick(pf)}
                    style={{ padding: 10 }}
                >
                    {pf.getPlatformIcon(50, !selected)}
                </IconButton>
            </Tooltip>

        })}
    </>
    )
}

interface IIntervalSelectCardContentProps {
    review: Review
    game: Game
}

function IntervalSelectCardContent(props: IIntervalSelectCardContentProps) {

    const { review, game } = props
    const intervals = game.getIntervals()
    return (
        <List>
            {Object.keys(intervals).map(int => {
                return <ListItem>{int}</ListItem>
            })}
        </List>
    )
}

interface IScoreSelectCardContentProps {
    review: Review
    updateCustomScoreNames: (scores: string[]) => void
}

function ScoreSelectCardContent(props: IScoreSelectCardContentProps) {

    const { review, updateCustomScoreNames } = props
    const [customScoreOptions, setCustomScoreOptions] = useState([
        "story",
        "gameplay",
        "innovation"
    ])

    const filter = createFilterOptions<string>()

    return (
        <CardContent>
            <Autocomplete
                multiple
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                filterSelectedOptions
                options={[...customScoreOptions]}
                onChange={(e, value) => {
                    let cloneVals = value.map(val => val.toLowerCase())
                    cloneVals.forEach(val => {
                        let newVals: string[] = []
                        if (!customScoreOptions.includes(val.toLowerCase())) {
                            newVals.push(val.toLowerCase())
                        }
                        setCustomScoreOptions(prev => [...prev, ...newVals])
                    })
                    // setCustomScoreNames(value)
                    updateCustomScoreNames(cloneVals)
                }}
                value={review.getCustomScoreNames()}
                getOptionLabel={option => toTitleCase(option)}
                filterOptions={(options, state) => {
                    const filtered = filter(options, state)
                    // console.log('state', state)
                    if (state.inputValue !== '') {
                        filtered.push(
                            `${state.inputValue}`,
                        );
                    }
                    return filtered
                }}
                renderInput={(params) => <TextField {...params} label="Custom Scores" variant="outlined" placeholder="Add custom scores" />}
            ></Autocomplete>
        </CardContent>
    )
}