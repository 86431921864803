import { fromJSON, getObjectStoreFromArray, ObjectStore } from "../util/json-utils"
// import { getAccessToken, getAccessTokenWithRefreshToken, getRefreshToken } from "./auth"
import merge from 'lodash.merge'
import cloneDeep from 'lodash.clonedeep'
import moment from "moment"

// const API_BASEPATH = "https://1bfc2hueb8.execute-api.ap-southeast-2.amazonaws.com/dev"
const API_BASEPATH = "https://dev.percentplayed.com"

export async function get<T>(type: new (props: any, id: string) => T, path: string, authRequired?: boolean, ignoreCache?: boolean): Promise<ObjectStore<T>> {
    return APIRequest(type, path, { credentials: 'same-origin' }, authRequired, ignoreCache)
}

export async function update<T>(type: new (props: any, id: string) => T, path: string, updatedData: T): Promise<ObjectStore<T>> {
    let options: RequestInit = {
        method: "PUT",
        body: JSON.stringify(updatedData),
        credentials: 'same-origin'
    }
    return APIRequest(type, path, options)
}

export async function post<T>(type: new (props: any, id: string) => T, path: string, options?: RequestInit, authRequired?: boolean, ignoreCache?: boolean): Promise<ObjectStore<T>> {
    if (options) {
        options.method = 'POST'
        options.credentials = 'same-origin'
    }
    else {
        options = { method: 'POST', credentials: 'same-origin' }
    }
    return APIRequest(type, path, options, authRequired, ignoreCache)
}

export async function APIRequest<T>(type: new (props: any, id: string) => T, path: string, init?: RequestInit | undefined, authRequired: boolean = true, ignoreCache: boolean = false): Promise<ObjectStore<T>> {

    let cache = await caches.open('percentPlayed')
    let options: RequestInit = cloneDeep(init) || {}
    let url = `${API_BASEPATH}/${path}`
    let request = new Request(url, options)
    let response = await cache.match(request, { ignoreVary: true })
    console.log('body :>> ', options);
    // caches.delete('percentPlayed')
    if (options.method === 'PUT' || ignoreCache) {
        response = undefined
    }
    // let response = await fetch(`${API_BASEPATH}/${path}`, options)
    if (!response) {
        console.log('no cached found');
        let reqOptions = options
        // let reqOptions = await setupHeaders(options, authRequired)
        let request = new Request(url, reqOptions)
        console.log('request :>> ', request);
        let keys = await cache.keys()
        console.log('cache :>> ', keys);
        response = await fetch(url, reqOptions)
        if (response.ok) {
            console.log('OK!')
            if (options.method !== 'PUT' && options.method !== 'POST') {
                await cache.put(request, response)
            }
        }
        // await cache.add(request)
        console.log('made it');
        response = await cache.match(request, { ignoreVary: true })
        console.log('response :>> ', response);
    }
    else {
        let date = response.headers.get('date')
        // console.log('headers :>> ', response.headers);
        if (date) {
            let responseDate = moment(date)
            console.log('date :>> ', date);
            console.log('responseDate :>> ', responseDate);
            console.log('responseDate.diff(moment.now() :>> ', responseDate.diff(moment.now()));
            if (moment().diff(responseDate, 'seconds') > 60) {
                console.log("Cache expired. Fetching...")
                let reqOptions = options
                // let reqOptions = await setupHeaders(options, authRequired)
                request = new Request(url, reqOptions)
                // await cache.add(request)
                response = await cache.match(request, { ignoreVary: true })
            }
        }
    }
    //TODO: Fix the response for POST
    console.log('headers :>> ', response?.headers.get('date'));
    if (response) {
        let responseJSON = await response.json()
        console.log('response :>> ', responseJSON);
        return getObjectStoreFromArray<T>(responseJSON.data.map((obj: any) => fromJSON(obj, type)), type)
    }
    else {
        return getObjectStoreFromArray<T>([], type)
    }
}
